import { DefaultTenant } from 'constants/defaultValues';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchOrganizations: [],
    fetchOrganizationsSuccess: ['organizations'],
    setOrganizationsLoading: ['isOrganizationsLoading'],
    setChangePassword: ['participantId', 'password'],
    setChangePasswordLoading: ['isChangePasswordLoading'],
    setCreateUser: ['values'],
    setCreateUserLoading: ['isCreateUserLoading'],
    setChangePasswordOk: ['changePasswordOk'],
    setCreateUserOk: ['createUserOk'],
    setUserErrors: ['error'],
    fetchTags: ['tags'],
    fetchTagsSuccess: ['tags'],
    fetchUserRole: ['authUser', 'tenant'],
    fetchUserRoleSuccess: ['userRole'],
    setUserRoleIsLoading: ['isloadingUserRole'],
    setLastLogin: ['userId'],
    setDeactivateUser: ['userId'],
    setDeactivateUserSuccess: ['user'],
    setUsersIsLoading: ['isUsersLoading'],
    setTenant: ['tenantId', 'authUser', 'replace'],
    setTenantSuccess: ['currentTenant'],
    setTenantLoading: ['isTenantLoading'],
    fetchTenantList: [],
    fetchTenantListSuccess: ['tenantList'],
    setTenantListLoading: ['isTenantListLoading'],
    setTenantScope: ['scope', 'tenantList'],
  },
  { prefix: '@BE-USER/' }
);

export const UserActions = Creators;
export const UserTypes = Types;

export const INITIAL_STATE = Immutable({
  isChangePasswordLoading: false,
  isCreateUserLoading: false,
  changePasswordOk: null,
  createUserOk: null,
  organizations: [],
  usersPaginated: {},
  isOrganizationsLoading: false,
  isUsersLoading: false,
  errors: [],
  userRole: null,
  isloadingUserRole: false,
  tags: {},
  areTagsLoading: false,
  currentTenant: DefaultTenant,
  tenantList: [],
  isTenantLoading: false,
  isTenantListLoading: false,
});

export const UserSelectors = {
  getChangePasswordLoading: ({ user }) => user.isChangePasswordLoading,
  getCreateUserLoading: ({ user }) => user.isCreateUserLoading,
  getChangePasswordOk: ({ user }) => user.changePasswordOk,
  getCreateUserOk: ({ user }) => user.createUserOk,
  getOrganizations: ({ user }) => user.organizations,
  getOrganizationsOptions: ({ user }) => [
    ...user.organizations.map((org, i) => ({
      value: org.id,
      label: org.name,
      key: i + 1,
    })),
  ],
  getOrganizationsLoading: ({ user }) => user.isOrganizationsLoading,
  getUsersPaginated: ({ user }) => user.usersPaginated,
  getUsersLoading: ({ user }) => user.isUsersLoading,
  getUserRole: ({ user }) => user.userRole,
  getUserTenantId: ({ user }) => user.userRole.tenant.id,
  getTags: ({ user }) => user.tags,
  getAreTagsLoading: ({ user }) => user.areTagsLoading,
  getUserRoleIsLoading: ({ user }) => user.isloadingUserRole,
  getCriticalUserErros: ({ user }) =>
    user.errors.find((error) => error?.unauthorized),
  getCurrentTenant: ({ user }) => user.currentTenant,
  getTenantList: ({ user }) => user.tenantList,
  getTenantLoading: ({ user }) => user.isTenantLoading,
  getTenantListLoading: ({ user }) => user.isTenantListLoading,
};

/* ------------- REDUCERS ACTIONS ------------------- */
const fetchOrganizationsSuccess = (state, { organizations }) =>
  state.merge({ organizations });

const setOrganizationsLoading = (state, { isOrganizationsLoading }) =>
  state.merge({ isOrganizationsLoading });

const setUsersIsLoading = (state, { isUsersLoading }) =>
  state.merge({ isUsersLoading });

const setChangePasswordLoading = (state, { isChangePasswordLoading }) =>
  state.merge({ isChangePasswordLoading });

const setCreateUserLoading = (state, { isCreateUserLoading }) =>
  state.merge({ isCreateUserLoading });

const setChangePasswordOk = (state, { changePasswordOk }) =>
  state.merge({ changePasswordOk });

const setCreateUserOk = (state, { createUserOk }) =>
  state.merge({ createUserOk });

const setUserErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

const fetchUserRoleSuccess = (state, { userRole }) => state.merge({ userRole });

const fetchTagsSuccess = (state, { tags }) => state.merge({ tags });

const setUserRoleIsLoading = (state, { isloadingUserRole }) =>
  state.merge({ isloadingUserRole });

const setDeactivateUser = (state, { user }) => state.merge({ user });

const setDeactivateUserSuccess = (state, { user }) => {
  const usersPaginated = { ...state.usersPaginated };
  const userDeactivated = user.platformUser;
  const rows = usersPaginated.rows.map((participant) =>
    participant.id === userDeactivated.id
      ? { ...participant, ...userDeactivated }
      : participant
  );
  usersPaginated.rows = rows;
  return state.merge({ usersPaginated });
};

const setTenantSuccess = (state, { currentTenant }) =>
  state.merge({ currentTenant });

const fetchTenantListSuccess = (state, { tenantList }) =>
  state.merge({ tenantList });

const setTenantLoading = (state, { isTenantLoading }) =>
  state.merge({ isTenantLoading });

const setTenantListLoading = (state, { isTenantListLoading }) =>
  state.merge({ isTenantListLoading });

export const UserReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_ORGANIZATIONS_SUCCESS]: fetchOrganizationsSuccess,
  [Types.SET_ORGANIZATIONS_LOADING]: setOrganizationsLoading,
  [Types.SET_CHANGE_PASSWORD_LOADING]: setChangePasswordLoading,
  [Types.SET_CREATE_USER_LOADING]: setCreateUserLoading,
  [Types.SET_CHANGE_PASSWORD_OK]: setChangePasswordOk,
  [Types.SET_CREATE_USER_OK]: setCreateUserOk,
  [Types.SET_USER_ERRORS]: setUserErrors,
  [Types.FETCH_USER_ROLE_SUCCESS]: fetchUserRoleSuccess,
  [Types.FETCH_TAGS_SUCCESS]: fetchTagsSuccess,
  [Types.SET_USER_ROLE_IS_LOADING]: setUserRoleIsLoading,
  [Types.SET_USERS_IS_LOADING]: setUsersIsLoading,
  [Types.SET_TENANT_SUCCESS]: setTenantSuccess,
  [Types.FETCH_TENANT_LIST_SUCCESS]: fetchTenantListSuccess,
  [Types.SET_TENANT_LOADING]: setTenantLoading,
  [Types.SET_TENANT_LIST_LOADING]: setTenantListLoading,
});
